var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full fixed h-full login flex justify-center items-center mdmax:relative overflow-y-auto"
  }, [_c('div', {
    staticClass: "w-2/6 bg-white shadow-base relative rounded-lg mdmax:w-full mdmax:h-screen"
  }, [_c('div', {
    staticClass: "px-20 py-20 mdmax:px-10 mdmax:py-10"
  }, [_vm._m(0), _c('div', {
    staticClass: "form mt-4 text-left"
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Username",
      "placeholder": "Silahkan masukan username",
      "error": _vm.errors['username']
    },
    on: {
      "enter": function ($event) {
        return _vm.doLogin();
      }
    },
    model: {
      value: _vm.payload.username,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "username", $$v);
      },
      expression: "payload.username"
    }
  })], 1), _c('div', {
    staticClass: "mb-8"
  }, [_c('TextField', {
    attrs: {
      "type": "password",
      "label": "Password",
      "placeholder": "Silahkan masukan password",
      "error": _vm.errors['password']
    },
    on: {
      "enter": function ($event) {
        return _vm.doLogin();
      }
    },
    model: {
      value: _vm.payload.password,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "password", $$v);
      },
      expression: "payload.password"
    }
  })], 1), _c('div', {
    staticClass: "login__form-submit mb-10"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.generalError ? _c('div', {
    staticClass: "text-system-error mb-4"
  }, [_vm._v(_vm._s(_vm.generalError))]) : _vm._e()]), _c('Button', {
    attrs: {
      "buttonText": "Login",
      "size": "full"
    },
    on: {
      "action": function ($event) {
        return _vm.doLogin();
      }
    }
  })], 1), _vm._m(1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left mb-10"
  }, [_c('p', {
    staticClass: "font-bold text-4xl mb-2"
  }, [_vm._v("Selamat datang kembali,")]), _c('p', {
    staticClass: "text-left"
  }, [_vm._v("Silahkan login akun kamu")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/edutest.png")
    }
  })]);

}]

export { render, staticRenderFns }